import swizzle from './swizzle.jpg';
import atm9 from './atm9.png'
import './App.css';

function App() {
  return (
    <div className="App hover">
      <img src={swizzle} style={{objectFit: 'cover', width: '100%', height: '99.6vh'}}  />
        <div className='floaty'>
          <img src={atm9} className='atm9'></img>
        <h1>JeffCraft ATM9 Minecraft Server</h1>
        <p>Download on <a target='_blank' href='https://www.curseforge.com/minecraft/modpacks/all-the-mods-9'>CurseForge</a> (through CurseForge launcher)</p>
        <p style={{color: 'darkgray'}}>Currently running version 0.3.0</p>
        <br />
        <p>Server accessible at <strong>atm.rswerner.com</strong></p>
        <hr />
        <blockquote cite='Owen'>
        <p>"lmfao I knew we should have picked a nukeless pack"</p>
        </blockquote>
        <p style={{marginLeft: '10rem'}}>- Owen Hunt</p>
        <blockquote>
          <hr />
          <p>"👀"</p>
        </blockquote>
        <p style={{marginLeft: '10rem'}}>- Bailey Conradt</p>
        
      </div>
    </div>
  );
}

export default App;
